import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import 'antd/dist/reset.css';
import './App.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import LoginForm from "./components/LoginForm"
import user from './model/user';
import Questions from './pages/questions';
import ModelManagement from './pages/models';
import QiniuUploader from './pages/qiniuTool';

function App() {

  const { token } = user;
  const [idx, setIdx] = useState(0)


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      user.token = token
    }
  })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIdx(newValue);
  };


  function getContentBy(idx: number): React.ReactNode {
    const contentMap = [
      <Questions />,
      <ModelManagement />,
      <QiniuUploader />
    ];

    return contentMap[idx] ?? null;
  }

  const renderContent = () => {
    if (token == null) return <LoginForm></LoginForm>;


    return <div>
      <Tabs value={idx} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="题库管理" />
        <Tab label="模型管理" />
        <Tab label="七牛云上传工具" />
      </Tabs>
      {getContentBy(idx)}

    </div>
  }

  return (
    <div className="App">
      {renderContent()}
    </div>
  );
}

export default App;
