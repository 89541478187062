/**
 * @ts-nocheck
 */
import * as XLSX from "xlsx"


export function getDataFromExcel(file, fieldMap) {


  return new Promise((resolve, reject) => {
    // Load the Excel file

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onloadend = () => {
      const arrayBuffer = reader.result;

      const workbook = XLSX.read(arrayBuffer);

      // Get the first sheet
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      // Get the field names from the first row
      const fieldNames = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];

      // Convert the sheet to an array of objects
      const data = XLSX.utils.sheet_to_json(sheet, { header: fieldNames });

      // Map the field names to the desired keys using the field map
      const mappedData = data.map(row => {
        const mappedRow = {};
        fieldNames.forEach(fieldName => {
          if (fieldMap[fieldName]) {
            mappedRow[fieldMap[fieldName]] = row[fieldName];
            mappedRow.courseType = getCourseTypeByFileName(file.name)
          }
        });
        return mappedRow;
      });

      const res = clearData(mappedData)
      resolve(res)
    }
  })





  // Write the data to a JSON file
  // const jsonFileName = filePath.replace('.xlsx', '.json');
  // fs.writeFileSync(jsonFileName, JSON.stringify(mappedData, null, 2));
}

function clearData(arr) {
  return arr.slice(1).filter(v => v.desc && v.desc !== "问答题题目描述")
}

export function getCourseTypeByFileName(filename) {
  const type = filename?.split?.("-")?.[1]?.split?.(".")?.[0];
  return type
}