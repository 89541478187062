import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { message, notification } from "antd";
import axios from "axios";
import React, { ChangeEvent, EventHandler, useEffect, useRef, useState } from "react";
import question from "../../model/questions";
import { getDataFromExcel } from "../../utils/excel2Obj";
import { getCourseTypeByFileName } from "../../utils/excel2Obj";
import request from "umi-request";

import { ProTable, TableDropdown } from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";

interface IQuestion {
  _id?: string,
  answer?: number | string;
  courseType: string;
  desc?: string;
  options?: string;
  pics?: string;
  type: string;
  needAudit: boolean;
}

interface Query extends Partial<IQuestion> {
  size?: number;
  page?: number;
}


const getColumns = (ref): ProColumns<IQuestion>[] => [
  {
    title: "题目",
    dataIndex: "desc",
    ellipsis: true,
    width: 400,
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
  },
  {
    title: "课程类型",
    dataIndex: "courseType",
    ellipsis: true,
  },
  {
    title: "题目图片",
    dataIndex: "pics",
    ellipsis: true,
  },
  {
    title: "题目类型",
    width: 80,
    dataIndex: "type",
    filters: true,
    onFilter: true,
    ellipsis: true,
    valueType: "select",
    valueEnum: {
      单选题: {
        text: "单选题",
      },
      多选题: {
        text: "多选题",
      },
      判断题: {
        text: "判断题",
      },
      填空题: {
        text: "填空题",
      },
      问答题: {
        text: "问答题",
      },
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
  },

  {
    title: "选择题选项",
    dataIndex: "options",
    ellipsis: true,
  },

  {
    title: "答案",
    dataIndex: "answer",
    ellipsis: true,
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
  },
  {
    title: "是否需要审核",
    dataIndex: "needAudit",
    valueType: 'radio',
    initialValue: false,
    valueEnum: {
      false: {
        text: "否",
      },
      true: {
        text: "是",
      },
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
  },

  {
    title: "操作",
    valueType: "option",
    key: "option",
    render: (text, record, _, action) => [
      <a
        key="editable"
        // rome-ignore lint/a11y/useValidAnchor: <explanation>
        onClick={() => {
          console.log(ref)
          action?.startEditable?.(record.desc);
        }}
      >
        编辑
      </a>,
      // rome-ignore lint/a11y/useValidAnchor: <explanation>
      <a key="view" style={{ color: "red" }} onClick={() => {
        console.log("record===", record)
        request("https://api.seekinglight.cn:3000/question/delete", {
          method: "DELETE",
          data: {
            _id: record._id
          }
        }).then((res) => {
          console.log("delete===", res)
          if (res.success) {
            message.success("删除成功")
            ref.current.reload();
          }
        })
      }}>
        删除
      </a>,
    ],
  },
];

const Questions: React.FC = () => {
  const fetchAllData = () => {
    axios
      .get("https://api.seekinglight.cn:3000/question/desc")
      .then(({ data }) => {
        question.descs = data.data;
      });
  };

  const tableRef = useRef(null)

  useEffect(() => {
    fetchAllData();
  }, []);

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)


  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    const courseType = getCourseTypeByFileName(file?.name);

    if (courseType == null) {
      message.error("文件名不合法，请检查");
      return;
    }

    const data = await getDataFromExcel(file, {
      "题目类型": "type",
      "题目描述": "desc",
      "正确答案": "answer",
      "选项": "options",
      "题目图片": "pics"
    });
    const { msg, success } = checkData(data);

    if (success === false) {
      notification.open({
        message: "导入错误，请检查excel",
        description: msg,
        duration: 10000,
      });
      return;
    }

    const needData = getNeedData(data);

    if (needData.length === 0) {
      message.info("后台已包含你所要上传的题目");
      return;
    }

    message.info("正在上传,请稍后...");

    const chunkData = getChunkData(needData);

    for (let { idx, chunk } of chunkData) {
      const percent = Math.floor((idx / needData.length) * 100);
      message.info(`正在上传,当前进度%${percent}`);
      await axios
        .post(`https://api.seekinglight.cn:3000/question/add`, { rows: chunk })
        .then((res) => { });
    }
    message.success(`本次成功上传${needData.length}道题目`);
    fetchAllData();
  };

  const queryQuestion = (query: Query) => {
    return request<{
      data: IQuestion[];
    }>("https://api.seekinglight.cn:3000/question/query", {
      method: "POST",
      data: query
    }).then((res) => {
      console.log("request==", res)
      // @ts-ignore
      setTotal(res.data.total)
      // @ts-ignore
      return { data: res.data.records }
    })
  }

  return (
    <ProTable<IQuestion>
      // @ts-ignore
      actionRef={tableRef}
      columns={getColumns(tableRef)}
      cardBordered
      onChange={(record) => {
        console.log("record===", record)
      }}
      onSubmit={(record) => {
        console.log("onSubmit====", record)
      }}
      request={async (params = {}, sort, filter) => {
        console.log("request=====", params)
        const descQuery = params?.desc;
        const { desc, current, pageSize, ...rest } = params;

        const clearedRest = getClearObj(rest)

        return queryQuestion({
          page: params.current,
          size: pageSize,
          ...clearedRest,
          // @ts-ignore
          desc: descQuery ? { $regex: descQuery, $options: "i" } : undefined,
        })
      }}
      editable={{
        type: 'multiple',
        onSave: async (rowKey, data, row) => {
          const { needAudit, ...rest } = data
          const newData = {
            ...rest,
            // @ts-ignore
            needAudit: toBoolean(needAudit)
          }
          request("https://api.seekinglight.cn:3000/question/update", {
            method: "PUT",
            data: newData,
          }).then((res) => {
            console.log("update===", res)
            if (res.success) {
              message.success("更新成功")
              tableRef.current.reload();
            }
          })
        }
      }}
      columnsState={{
        persistenceKey: 'pro-table-singe-demos',
        persistenceType: 'localStorage',
        onChange(value) {
          console.log('value: ', value);
        },
      }}
      rowKey="desc"
      search={{
        labelWidth: 'auto',
      }}
      options={{
        setting: {
          listsHeight: 400,
        },
      }}
      pagination={{
        current: page,
        total,
        onChange: (page) => setPage(page),
      }}
      dateFormatter="string"
      headerTitle="题库管理"
      toolBarRender={() => [
        <Button
          variant="contained"
          component="label"
        >
          点此上传excel
          <input hidden multiple type="file" onChange={handleFileChange} />
        </Button>,
        <Button onClick={() => {
          window.location.assign("https://machine-tools.seekinglight.cn/#/create")
        }} variant="contained" component="label">
          新建题目
        </Button>
      ]}
    />
  );

};

export default Questions;

function getChunkData(needData: unknown[]) {
  const chunkData: { idx: number; chunk: unknown[] }[] = [];
  const chunkSize = 50;

  for (let i = 0; i < needData.length; i += chunkSize) {
    chunkData.push({
      idx: i,
      chunk: needData.slice(i, i + chunkSize),
    });
  }
  return chunkData;
}

function checkData(data: any[]) {
  const errorRes = [];
  data.forEach((row, idx) => {
    const rowIdx = idx + 1;
    if (row.type == null) {
      errorRes.push(`第${rowIdx}行未提供题目类型!`);
    }
    if (row.desc == null) {
      errorRes.push(`第${rowIdx}行未提供题目描述!`);
    }
    if (row.type === "判断题") {
      if (row.answer == null) {
        errorRes.push(`${rowIdx}行的判断题未提供答案!`);
      }
    }

    if (row.type === "单选题") {
      if (row.answer == null) {
        errorRes.push(`${rowIdx}行的单选题未提供答案!`);
      }

      if (row.options == null) {
        errorRes.push(`${rowIdx}行的单选题未提供选项!`);
      }
    }

    if (row.type === "填空题") {
      if (row.answer == null) {
        errorRes.push(`${rowIdx}行的填空题未提供答案!`);
      }
    }
  });

  if (errorRes.length) {
    return {
      success: false,
      msg: errorRes.join("\n\n"),
    };
  }

  return {
    success: true,
    msg: "ok",
  };
}

function getNeedData(questions: any[]) {
  // 筛选出需要上传的题目
  const keys = question.descs;
  return questions.filter((q) => !keys.includes(q.desc)).map(q => ({ ...q, needAudit: false }))
}


function getClearObj(rowObj: object) {
  Object.keys(rowObj).forEach((key) => {
    const val = rowObj[key];
    if (val == null || val === "") {
      rowObj[key] = undefined
    }
  })
  return rowObj
}

function toBoolean(val: "true" | "false") {
  if (val === "true") return true
  if (val === "false") return false
}