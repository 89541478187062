import React, { useState } from 'react';
import { Upload, message, Typography, Button, Space } from 'antd';
import { InboxOutlined, CopyOutlined } from '@ant-design/icons';
import type { UploadProps, UploadFile } from 'antd';
import * as qiniu from 'qiniu-js';
import * as CryptoJS from 'crypto-js';

const { Dragger } = Upload;
const { Text } = Typography;

const QINIU_DOMAIN = 'http://imgs.seekinglight.cn';
const BUCKET = 'machine-club';
const ACCESS_KEY = 'GhvZ5DDzGbBNgAZgWZ9TL8lQ_ti9_afFyul_K4IT';
const SECRET_KEY = 'BfK7olO8_bZQ-osQ7YViQA7roJdPpI2ofgdxCvr4';

const QiniuUploader: React.FC = () => {
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const getQiniuToken = () => {
    const putPolicy = {
      scope: BUCKET,
      deadline: Math.floor(Date.now() / 1000) + 3600,
    };
    const putPolicyString = JSON.stringify(putPolicy);
    const encodedPutPolicy = btoa(putPolicyString);
    const sign = CryptoJS.HmacSHA1(encodedPutPolicy, SECRET_KEY);
    const encodedSign = sign.toString(CryptoJS.enc.Base64).replace(/\//g, '_').replace(/\+/g, '-');
    return `${ACCESS_KEY}:${encodedSign}:${encodedPutPolicy}`;
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    fileList,
    customRequest: ({ file, onProgress, onSuccess, onError }) => {
      const token = getQiniuToken();
      const observable = qiniu.upload(file as File, null, token, {}, {
        region: qiniu.region.z2
      });

      observable.subscribe({
        next: (res) => {
          onProgress?.({ percent: res.total.percent });
        },
        error: (err) => {
          onError?.(err);
          message.error(`${(file as File).name} 上传失败`);
        },
        complete: (res) => {
          const url = `${QINIU_DOMAIN}/${res.key}`;
          setUploadedUrls(prev => [...prev, url]);
          onSuccess?.(res, file as any);
          message.success(`${(file as File).name} 上传成功`);
        }
      });
    },
    onChange(info) {
      setFileList(info.fileList);
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} 文件上传成功`);
      } else if (status === 'error') {
        message.error(`${info.file.name} 文件上传失败`);
      }
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      
      // 同时从 uploadedUrls 中移除对应的 URL
      setUploadedUrls(prev => prev.filter((_, i) => i !== index));
    },
  };

  const copyAndClear = () => {
    if (uploadedUrls.length === 0) {
      message.warning('没有可复制的链接');
      return;
    }

    const text = uploadedUrls.join('\n');
    navigator.clipboard.writeText(text).then(() => {
      message.success('所有链接已复制到剪贴板');
      setUploadedUrls([]); // 清空上传链接列表
      setFileList([]); // 清空文件列表
    }).catch(() => {
      message.error('复制失败，请手动复制');
    });
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
        <p className="ant-upload-hint">
          支持单个或批量上传图片
        </p>
      </Dragger>
      {uploadedUrls.length > 0 && (
        <>
          <div>
            <Text strong>上传成功的图片链接：</Text>
            <ul>
              {uploadedUrls.map((url, index) => (
                <li key={index}>
                  <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                </li>
              ))}
            </ul>
          </div>
          <Button 
            icon={<CopyOutlined />} 
            onClick={copyAndClear}
            type="primary"
          >
            复制所有链接到剪贴板并清空
          </Button>
        </>
      )}
    </Space>
  );
};

export default QiniuUploader;