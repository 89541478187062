import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { message, notification } from "antd";
import axios from "axios";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { ProTable, TableDropdown } from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { getDataFromExcel } from "../../utils/excel2Obj";
import { getCourseTypeByFileName } from "../../utils/excel2Obj";
import request from "umi-request";
import jmodel from "../../model/jmodel";

interface IModel {
  _id?: string;
  title: string;
  desc?: string;
  pics: string;
  link: string;
  pwd: string;
  price: string;
}

interface Query extends Partial<IModel> {
  size?: number;
  page?: number;
  title?: string;
}

const getColumns = (ref): ProColumns<IModel>[] => [
  {
    title: "标题",
    dataIndex: "title",
    ellipsis: true,
    width: 200,
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
  },
  {
    title: "描述",
    dataIndex: "desc",
    ellipsis: true,
    search: false
  },
  {
    title: "图片",
    dataIndex: "pics",
    ellipsis: true,
    search: false
  },
  {
    title: "链接",
    dataIndex: "link",
    ellipsis: true,
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
    search: false
  },
  {
    title: "密码",
    dataIndex: "pwd",
    ellipsis: true,
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
    search: false
  },
  {
    title: "价格",
    dataIndex: "price",
    ellipsis: true,
    formItemProps: {
      rules: [
        {
          required: true,
          message: "此项为必填项",
        },
      ],
    },
    search: false
  },
  {
    title: "操作",
    valueType: "option",
    key: "option",
    render: (text, record, _, action) => [
      <a
        key="editable"
        onClick={() => {
          action?.startEditable?.(record._id);
        }}
      >
        编辑
      </a>,
      <a key="view" style={{ color: "red" }} onClick={() => {
        request("https://api.seekinglight.cn:3000/models/" + record._id, {
          method: "DELETE"
        }).then((res) => {
          if (res.success) {
            message.success("删除成功");
            ref.current.reload();
          }
        })
      }}>
        删除
      </a>,
    ],
  },
];

const ModelManagement: React.FC = () => {
  const tableRef = useRef<ActionType | null>(null);
  const [total, setTotal] = useState(0);

  const fetchAllData = () => {
    axios
      .get("https://api.seekinglight.cn:3000/models")
      .then(({ data }) => {
        setTotal(data.total);
      });

    axios
      .get("https://api.seekinglight.cn:3000/models/titles")
      .then(({ data }) => {
        jmodel.titles = data?.data ?? []
      });
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const courseType = getCourseTypeByFileName(file?.name);

    if (courseType == null) {
      message.error("文件名不合法，请检查");
      return;
    }

    const excelData = await getDataFromExcel(file, {
      "链接": "link",
      "提取码": "pwd",
      "标题": "title",
      "描述": "desc",
      "图片": "pics",
      "价格": "price"
    });
    const { msg, success } = checkData(excelData);

    if (!success) {
      notification.open({
        message: "导入错误，请检查excel",
        description: msg,
        duration: 10000,
      });
      return;
    }

    const needData = getNeedData(excelData);

    if (needData.length === 0) {
      message.info("后台已包含你所要上传的模型");
      return;
    }

    message.info("正在上传,请稍后...");

    const chunkData = getChunkData(needData);
    console.log("chunkData=====", chunkData);

    for (let { idx, chunk } of chunkData) {
      const percent = Math.floor((idx / needData.length) * 100);
      message.info(`正在上传,当前进度%${percent}`);
      await axios
        .post(`https://api.seekinglight.cn:3000/models/add`, { rows: chunk })
        .then((res) => { });
    }
    message.success(`本次成功上传${needData.length}个模型`);
    fetchAllData();
  };

  const queryModels = (query: Query) => {
    return request<{ data: IModel[], total: number }>("https://api.seekinglight.cn:3000/models", {
      method: "GET",
      params: query,
    }).then((res) => {
      setTotal(res.total);
      return { data: res.data };
    });
  };

  return (
    <ProTable<IModel>
      actionRef={tableRef}
      columns={getColumns(tableRef)}
      cardBordered
      request={async (params = {}, sort, filter) => {
        const { current, pageSize, title, ...rest } = params;
        return queryModels({
          page: current,
          size: pageSize,
          ...rest,
        });
      }}
      editable={{
        type: 'multiple',
        onSave: async (rowKey, data, row) => {
          request("https://api.seekinglight.cn:3000/models/" + data._id, {
            method: "PUT",
            data,
          }).then((res) => {
            if (res.success) {
              message.success("更新成功");
              tableRef.current.reload();
            }
          });
        }
      }}
      columnsState={{
        persistenceKey: 'pro-table-singe-demos',
        persistenceType: 'localStorage',
        onChange(value) {
          console.log('value: ', value);
        },
      }}
      rowKey="_id"
      search={{
        labelWidth: 'auto',
      }}
      options={{
        setting: {
          listsHeight: 400,
        },
      }}
      pagination={{
        total,
      }}
      dateFormatter="string"
      headerTitle="模型管理"
      toolBarRender={() => [
        <Button
          variant="contained"
          component="label"
        >
          点此上传excel
          <input hidden multiple type="file" onChange={handleFileChange} />
        </Button>,
        <Button onClick={() => {
          window.location.assign("https://machine-tools.seekinglight.cn/#/create")
        }} variant="contained" component="label">
          新建模型
        </Button>
      ]}
    />
  );
};

export default ModelManagement;

function getChunkData(needData: unknown[]) {
  const chunkData: { idx: number; chunk: unknown[] }[] = [];
  const chunkSize = 50;

  for (let i = 0; i < needData.length; i += chunkSize) {
    chunkData.push({
      idx: i,
      chunk: needData.slice(i, i + chunkSize),
    });
  }
  return chunkData;
}

function checkData(excelData: any[]) {
  const errorRes = [];
  excelData.forEach((row, idx) => {
    const rowIdx = idx + 1;
    if (!row.title) {
      errorRes.push(`第${rowIdx}行未提供模型标题!`);
    }
    if (!row.link) {
      errorRes.push(`第${rowIdx}行未提供模型链接!`);
    }
    if (!row.pics) {
      errorRes.push(`第${rowIdx}行未提供模型图片链接!`);
    }
    if (!row.pwd) {
      errorRes.push(`第${rowIdx}行未提供模型密码!`);
    }
    if (!row.price) {
      errorRes.push(`第${rowIdx}行未提供价格!`);
    }
  });

  if (errorRes.length) {
    return {
      success: false,
      msg: errorRes.join("\n\n"),
    };
  }

  return {
    success: true,
    msg: "ok",
  };
}

function getNeedData(excelData: any[]) {
  // 筛选出需要上传的模型
  const keys = jmodel.titles;
  console.log("keys====", keys);

  return excelData.filter((q) => !keys.includes(q.title));
}

function getClearObj(rowObj: object) {
  Object.keys(rowObj).forEach((key) => {
    const val = rowObj[key];
    if (val == null || val === "") {
      rowObj[key] = undefined;
    }
  });
  return rowObj;
}
